var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main contract-list" },
    [
      _c("van-search", {
        staticClass: "van-main-search",
        attrs: {
          shape: "round",
          "left-icon": "",
          "show-action": "",
          placeholder: "合同名称/合同编号查询"
        },
        on: { search: _vm.onSearch },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function() {
              return [
                _c("div", { on: { click: _vm.onSearch } }, [_vm._v(" 搜索 ")])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.searchForm.searchValue,
          callback: function($$v) {
            _vm.$set(_vm.searchForm, "searchValue", $$v)
          },
          expression: "searchForm.searchValue"
        }
      }),
      _c(
        "div",
        { staticClass: "van-refresh-list" },
        [
          _c(
            "van-pull-refresh",
            {
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing"
              }
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": _vm.finishedText
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.list, function(item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "contract-item" },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "合同名称",
                          value: _vm.utils.isEffectiveCommon(item.contractName)
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "合同编号",
                          value: _vm.utils.isEffectiveCommon(
                            item.contractNumber
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "签署时间",
                          value: _vm.utils.dateFormat(item.signDate)
                        }
                      }),
                      _c(
                        "van-cell",
                        { staticClass: "text-cell" },
                        [
                          _c(
                            "van-button",
                            {
                              staticClass: "lf",
                              attrs: {
                                plain: "",
                                round: "",
                                size: "small",
                                type: "info",
                                "native-type": "button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.detail(item)
                                }
                              }
                            },
                            [_vm._v(" 查看详情 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "lr",
                              attrs: {
                                size: "small",
                                type: "default",
                                disabled: ""
                              }
                            },
                            [_vm._v(" " + _vm._s(item.state_Name) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }